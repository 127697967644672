module.exports = new function ()
{

    var toggleElement = function () {
        var _this = $(this),
            targetData = _this.data('target'),
            targetElem = $(targetData);
            targetElem.toggleClass('is-active');
    }

    $('.js-search').on('click', toggleElement);

}
