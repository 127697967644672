module.exports = new function ()
{
    var accordion = $('.faq__accordion'),
        accordionButton = $('.faq__accordion-header'),
        accordionText = $('.faq__accordion-text'),
        accordionCross = $('.faq__accordion-cross');
        accordionText.hide();

    accordionButton.on('click', function () {
        var _this = $(this);
            _this.next().slideToggle(300);
            _this.find(accordionCross).toggleClass('is-active');
            accordionText.not(_this.next()).slideUp(300);
            accordionCross.not(_this.find(accordionCross)).removeClass('is-active');
    });
}
