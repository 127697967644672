module.exports = new function sliderModule ()
{
    var jQueryBridget = require('jquery-bridget');
    var Flickity = require('flickity');

    jQueryBridget( 'flickity', Flickity, $ );

    function initImageSlider () {
        var imageSlider = $('.js-image-slider').flickity({
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            freeScroll: true,
            watchCss: true,
        });

        var flkty = imageSlider.data('flickity');

        $('.js-image-btn').on('click', function() {
            updateStatus();
        });

        var sliderCount = $('.js-image-count');

        function updateStatus() {
            if ( flkty ) {
                var slideNumber = flkty.selectedIndex + 1;
                var countSlides = slideNumber + '/' + flkty.cells.length;
                sliderCount.text(countSlides);
            }
        }
        updateStatus();

        flkty.on( 'select', updateStatus );

        // previous
        $('.js-image-prev').on( 'click', function() {
            imageSlider.flickity('previous');
        });
        // next
        $('.js-image-next').on( 'click', function() {
            imageSlider.flickity('next');
        });

    }

    if ( $('.js-image-slider').length ) {
        initImageSlider();
    }

    function initVideoSlider () {
        var videoSlider = $('.js-video-slider').flickity({
            prevNextButtons: false,
            pageDots: false,
            wrapAround: true,
            freeScroll: true,
            watchCss: true,
        });

        var flktyVideo = videoSlider.data('flickity');

        $('.js-video-btn').on('click', function() {
            updateStatus();
        });

        var sliderCount = $('.js-video-count');

        function updateStatus() {
            if ( flktyVideo ) {
                var slideNumber = flktyVideo.selectedIndex + 1;
                var countSlides = slideNumber + '/' + flktyVideo.cells.length;
                sliderCount.text(countSlides);
            }
        }
        updateStatus();

        flktyVideo.on( 'select', updateStatus );

        // previous
        $('.js-video-prev').on( 'click', function() {
            videoSlider.flickity('previous');
        });
        // next
        $('.js-video-next').on( 'click', function() {
            videoSlider.flickity('next');
        });

    }

    if ( $('.js-video-slider').length ) {
        initVideoSlider();
    }

    $('.header_slider').flickity({
        prevNextButtons: false,
        pageDots: false,
        watchCss: true,
        autoPlay: 5000,
        setGallerySize: false
    });

}
