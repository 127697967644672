module.exports = new function ()
{
    var dropdown = $('.front-dropdown'),
        dropdownButton = $('.js-dropdown-btn'),
        dropdownSubpage = $('.sub-dropdown'),
        dropdownOption = dropdownSubpage.find('option');

        // Set link on page load.
        dropdownButton.attr('href', dropdown.find(':selected').data('link'));

        dropdown.on('change', function (e) {
            e.preventDefault();
            var _this = $(this),
                chosenOption = _this.find(':selected').data('link');
                dropdownButton.attr('href', chosenOption);
        });

        dropdownOption.each(function () {
            var _this = $(this);

                if ( _this.data('link') == window.location.href )
                {
                    _this.prop('selected', true);
                }
        });

        dropdownSubpage.on('change', function (e) {
            e.preventDefault();
            var _this = $(this),
                chosenOption = _this.find(':selected').data('link');
                window.open(chosenOption, '_self');
        });

        function start() {
            var $opt = $('select.sub-dropdown').find("option:selected");
            var $span = $('<span>').addClass('tester').text($opt.text());

            $span.css({
                'font-family': $opt.css('font-family'),
                'font-style': $opt.css('font-style'),
                'font-weight': $opt.css('font-weight'),
                'font-size': $opt.css('font-size')
            });

            $('body').append($span);
            // The 30px is for select open icon - it may vary a little per-browser
            $('select.sub-dropdown').width($span.width());
            $span.remove();
        }
        start();
}
