module.exports = new function ()
{
    var sliderContent = $('.image-slider__content'),
        sliderImage = $('.slider'),
        _window = $(window);

    function moveTheElement ( el ) {
        el.each(function( i, el ) {
          $( el ).insertBefore( $( el ).prev() );
        });
    }

    $(window).on('load resize', function() {

        if ( $(window).width() <= 768  ) {
          moveTheElement( sliderImage );
        }
        else if ( $(window).width() > 768 ) {
          moveTheElement( sliderContent );
        }

        $(window).width();

    });
}
