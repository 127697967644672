module.exports = new function ()
{
    const backTop = $('.js-scroll-top');

    $(window).scroll(function()
    {
        if ($(this).scrollTop() > 300) {
            backTop.addClass('visible');
        } else {
            backTop.removeClass('visible');
        }
    });

    backTop.on('click', function (e) {
        e.preventDefault();

        $('body, html').animate({
             scrollTop: 0
         }, 300);
    });
}
