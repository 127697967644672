window.jQuery = require('jquery');
window.$ = require('jquery');
var dropdownJS = require('./dropdownMenu.js');
var accordionJS = require('./accordion.js');
var sliderJS = require('./slider.js');
var moveJS = require('./moveElements.js');
var searchJS = require('./search.js');
var interactionJS = require('./interaction.js');

(function($) {

});
